import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs-compat';
import { TimeoutUtility } from 'src/app/services/timeout_utility';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit, OnDestroy {

  @Input()
  labelBackButton: string;
  @Input()
  labelContinueButton: string;
  @Input()
  messageBody: string;

  isAlertOpen = false;
  alertButtons = ['Action'];

  // Timer variables
  idleTimerLeft: string;
  timeRemain: number;
  FULL_DASH_ARRAY = 283;

  secondLevelUserSubscription: Subscription;


  constructor(private modalCtrl: ModalController, private timeoutService: TimeoutUtility) {

    this.secondLevelUserSubscription = this.timeoutService.secondLevelUserIdleChecker.subscribe((status: string) => {
      this.initiateSecondTimer(status);
    });
  }

  ngOnDestroy(): void {
    console.log('AlertModalComponent - ngOnDestroy');
    this.secondLevelUserSubscription.unsubscribe();
  }

  ngOnInit() {
    console.log('AlertModalComponent - ngOnInit - Parameters:', this.labelBackButton, this.labelContinueButton, this.messageBody);
  }

  dismissModal(value) {
    console.log('AlertModalComponent - dismissModal', value);
    // this.timeoutService.secondLevelUserIdleChecker.unsubscribe();
    // this.timeoutService.ngOnDestroy();

    // this.timeoutService.resetMainTimer();
    // TimeoutUtility.runSecondTimer = false;
    return this.modalCtrl.dismiss(null, value);
  }

  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }

  initiateFirstTimer = (status: string) => {

    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;
    }
  }

  initiateSecondTimer = (status: string) => {
    console.log("AlertModalComponent - initiateSecondTimer", status);
    switch (status) {
      case 'INITIATE_SECOND_TIMER':
        break;

      case 'SECOND_TIMER_STARTED':
        break;

      case 'SECOND_TIMER_STOPPED':
        //this.logout();
        console.log('WE SHOULD LOG OUT');
        this.modalCtrl.dismiss(null, 'back');
        break;

      default:
        this.idleTimerLeft = this.formatTimeLeft(Number(status));
        break;
    }
  }

  /**
  * Draw timer circle
  */
  formatTimeLeft = (time: number) => {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);

      this.setCircleDasharray(seconds);

      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        console.log(min);
        seconds -= (min * 60);
      }

      return `${min}:${seconds}`;
    }
  }

  setCircleDasharray = (elapsedTime: number) => {
    const timeLimit = this.timeoutService.FINAL_LEVEL_TIMER_VALUE_IN_MIN * 60;

    this.timeRemain = elapsedTime / timeLimit;
    const circleDasharray = `${(this.timeRemain * this.FULL_DASH_ARRAY).toFixed(0)} 283`;

    if (document.getElementById('base-timer-path-remaining')) {
      document.getElementById('base-timer-path-remaining').setAttribute('stroke-dasharray', circleDasharray);
    }
  }

}
