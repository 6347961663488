import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.page.html',
  styleUrls: ['./info.page.scss'],
})
export class InfoPage {

  mainTitle: string = environment.APP_NAME;

  constructor(public viewCtrl: ModalController) {
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad InfoPage');
  }

  close() {
    this.viewCtrl.dismiss();
  }

}
