import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-table-contents',
  templateUrl: './table-contents.page.html',
  styleUrls: ['./table-contents.page.scss'],
})
export class TableContentsPage {

  constructor(private viewCtrl: ModalController) {
  }



  ionViewDidLoad() {
    console.log('ionViewDidLoad TableContentsPage');
  }

  close() {
    this.viewCtrl.dismiss();
  }

}
