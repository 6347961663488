import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';
import { DbService } from 'src/app/services/db.service';
import { HttpService } from 'src/app/services/http.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MoreInfoModalPage } from './more-info-modal/more-info-modal.page';

@Component({
  selector: 'app-code-registration',
  templateUrl: './code-registration.page.html',
  styleUrls: ['./code-registration.page.scss'],
})
export class CodeRegistrationPage {

  currentCode: any = { code: '' };
  form: FormGroup;
  loading = false;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    public util: UtilsService,
    public profileProvider: ProfileService,
    public service: HttpService,
    private modalCtrl: ModalController) {

    this.form = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad CodeRegistrationPage');
  }

  async submitCode() {
    console.log('Entering submitCode method');
    this.loading = true;

    if (!this.currentCode.code) {
      this.util.presentToast('Please enter code');
    } else {

      const profileResult = await this.profileProvider.getProfile();
      if (!profileResult.email) {
        console.log('User don\'t logged');
        this.util.presentToast('Please log into your account before you submit the code.');
        this.loading = false;
        return;
      }

      console.log('Profile to Send', profileResult);
      this.currentCode.email = profileResult.email;

      this.service.redeemClassPromCode(this.currentCode, DbService.studentPortalUrl)
        .subscribe(
          async (response: any) => {
            console.log('Redeem Response', response);

            if (response.code.type === 'PROMCODE') {
              console.log('Entering for PromCode');

              profileResult.updatedAt = null;
              profileResult.purchaseReceipt = '{"transactionId": "Redeemed with PROMCODE : ' + response.code.code + '"}';
              profileResult.premiumMode = true;
              profileResult.timeOfPurchase = new Date().toJSON();
              console.log('Redeem Code - Profile to be updated', profileResult);
              const resultingProfile = await this.profileProvider.syncProfile(profileResult);

              this.util.presentToast('Code Processed Succesfully');
              this.loading = false;
              this.navCtrl.navigateRoot(['home']);

            } else {
              profileResult.updatedAt = null;
              profileResult.purchaseReceipt = '{"transactionId": "Redeemed with CLASSCODE : ' + response.code.code + '"}';
              profileResult.premiumMode = true;
              profileResult.timeOfPurchase = new Date().toJSON();
              console.log('Redeem Code - Profile to be updated', profileResult);
              const resultingProfile = await this.profileProvider.syncProfile(profileResult);
              this.util.presentToast('Class code processed succesfully');
              this.loading = false;
              this.navCtrl.navigateRoot(['home']);
            }
          },
          error => {
            this.loading = false;
            console.log('Redeem:Error', error);
            if (error === undefined) {
              this.util.presentToast(
                'Failed to communicate with server.  Please try again later.'
              );
            } else {
              this.util.presentToast(error);
            }
          }
        );
    }
  }

  close() {
    this.navCtrl.navigateRoot(['home']);
  }

  async moreInfoModal() {
    const modal = await this.modalCtrl.create({
      component: MoreInfoModalPage,
      cssClass: 'more-info-modal'
    });
    modal.onDidDismiss();
    modal.present();
  }

}
