import { HttpSentEvent } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {

  email: any = '';
  EMAIL_VALID_CHECK = /^.+@.+\..+$/;
  submitted = false;
  success = false;
  fail = false;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private view: ModalController,
    private toast: UtilsService,
    private http: HttpService,
    private alertController: AlertController
  ) { }

  ngOnInit(): void {
    console.log('ForgotPasswordPage OnInit');
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad ForgotPasswordPage');
  }

  close() {
    this.view.dismiss();
  }

  loginPage() {
    this.navCtrl.navigateForward('LoginPage');
  }

  sendLink() {
    if (!this.email) {
      this.toast.presentToast('Please enter email address.');
    } else if (!this.EMAIL_VALID_CHECK.test(this.email)) {
      this.toast.presentToast('Please enter valid email address.');
    } else {
      this.http.sendPasswordResetEmail(this.email).subscribe((result: any) => {
        this.submitted = true;

        if (result.success) {
          this.success = true;
          // let alert = this.alertController.create({
          //   title: "You should receive email with reset instructions soon.",
          //   buttons: [
          //     {
          //       text: "Dismiss",
          //       handler: () => {
          //         // user has clicked the alert button
          //         // begin the alert's dismiss transition
          //         let navTransition = alert.dismiss();

          //         navTransition.then(() => {
          //           this.navCtrl.pop();
          //         });
          //         return false;
          //       }
          //     }
          //   ]
          // });
          // alert.present();
        } else {
          this.fail = true;
          // let alert = this.alertController.create({
          //   title: "Password reset request failed.",
          //   buttons: [
          //     {
          //       text: "Dismiss",
          //       handler: () => {
          //         // user has clicked the alert button
          //         // begin the alert's dismiss transition
          //         let navTransition = alert.dismiss();

          //         navTransition.then(() => {
          //           this.navCtrl.pop();
          //         });
          //         return false;
          //       }
          //     }
          //   ]
          // });
          // alert.present();
        }
      });
    }
  }

}
