/* eslint-disable no-underscore-dangle */
import { Component } from '@angular/core';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/models/profile';
import { Unit } from 'src/models/unit';
import { PremiumVersionPage } from '../pages/premium-version/premium-version.page';
import { UnitPage } from '../pages/unit/unit.page';
import { DbService } from '../services/db.service';
import { ProfileService } from '../services/profile.service';

declare let ga;

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  mainTitle: string = environment.APP_NAME;
  title = 'Home';
  //unitPage: any = UnitPage; // Useful for Navigation
  allUnits: Unit[] = [];
  profile: Profile;
  loading = true;
  userInfo: any;
  unit: Unit;

  constructor(
    private navCtrl: NavController,
    private dbProvider: DbService,
    private profileProvider: ProfileService,
    private alertController: AlertController,
    public gaNative: GoogleAnalytics,
    public platform: Platform
  ) {

    this.userInfo = localStorage.getItem('userInfo');

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.gaNative
          .trackEvent('Home Page', 'Load', 'Load', 1)
          .then(() => { })
          .catch(e => console.log('Error tracking GoogleAnalytics', e));
      } else {
        // This is for the Browser
        ga('set', 'page', 'Home page');
        ga('send', 'pageview');
      }
    });

    //unitService.getUnits().then(units => this.allUnits = units);
    //console.log("Unit Data ", this.allUnits);
    this.loading = true;
    this.loadUnits();
  }

  /**
   * This is essential because coming back to this page to view Units
   * should ensure that we can enter Unit 6 if profile is now in
   * premium mode after payment.
   */
  ionViewDidEnter() {
    this.profileProvider.getProfile().then(stats => {
      if (stats) {
        this.profile = stats;
        if (!this.loading && this.userInfo) {
          this.profileProvider.syncProfile(this.profile);
        }
      }
    });
  }

  ionViewWillEnter() {
    if (this.allUnits.length > 0) {
      this.loading = false;
    } else {
      this.loading = true;
    }
  }



  async presentLoadErrorAlert(error) {
    let errorOutput = 'Could not load database in a timely manner.';
    if (error) {
      errorOutput = error.errors[0];
    }
    const alert = await this.alertController.create({
      header: 'Could not load database',
      subHeader:
        'Sorry! Please exit app and try again later. Error: ' + errorOutput,
      buttons: ['Dismiss']
    });
    alert.present();
  }

  /**
   * This function simply returns the parameter necessary for the
   * NavPush.
   */

  /*unitParams(selectedUnit: Unit): any {
    console.log("I'm called with : " + selectedUnit._id);
    //return { "id": selectedUnit.id };
  }*/

  async openUnit(unit: Unit) {

    try {
      this.loading = true;
      const remoteUnit: Unit[] = await this.dbProvider.getRemoteUnit(unit._id);
      this.loading = false;

      if (this.platform.is('cordova')) {
        if (this.platform.is('android')) {
          this.gaNative
            .trackEvent('Unit', 'Click', unit.name, 1)
            .then(() => { })
            .catch(e => console.log('Error tracking GoogleAnalytics', e));
        } else if (this.platform.is('ios')) {
          this.gaNative
            .trackEvent('Unit', 'Click', unit.name, 1)
            .then(() => { })
            .catch(e => console.log('Error tracking GoogleAnalytics', e));
        }
      } else {
        ga('send', 'event', {
          eventCategory: 'Unit',
          eventAction: 'Click',
          eventValue: 1,
          eventLabel: unit.name
        });
      }

      if (
        (!this.profile || !this.profile.premiumMode) && unit.title.toLowerCase().includes('review') ||
        (!this.profile || !this.profile.premiumMode) && unit.title.toLowerCase().includes('building') ||
        (!this.profile || !this.profile.premiumMode) && unit.title.toLowerCase().includes('making') ||
        (!this.profile || !this.profile.premiumMode) && unit.title.toLowerCase().includes('putting') ||
        (!this.profile || !this.profile.premiumMode) && unit.title.toLowerCase().includes('forming')

      ) {
        const alert = await this.alertController.create({
          header: '',
          subHeader:
            'This is a Premium feature. Would you like to learn more about the Premium version of this app? ',
          buttons: [
            {
              text: 'Yes',
              handler: () => {
                this.navCtrl.navigateForward([PremiumVersionPage], { state: { color: unit.color } });
              }
            },
            {
              text: 'Not now',
              handler: () => { }
            }
          ]
        });
        alert.present();
      } else {
        this.unit = remoteUnit[0];
        this.navCtrl.navigateForward(['unit'], { state: { unit: this.unit } });
      }

    } catch (error) {
      console.log('Error getting remote Unit', error);
    }
  }

  private async loadUnits() {

    if (!this.dbProvider.liveSync) {
      this.allUnits = await this.dbProvider.getRemoteUnits();
    }
    this.loading = false;

    this.dbProvider.initializeDB().then(r => {
      if (r.errors.length > 0) {
        this.presentLoadErrorAlert(r);
        this.loading = false;
      } else {
        this.dbProvider.getUnits().then(result => {
          if (!result || result.length === 0) {
            this.presentLoadErrorAlert(undefined);
          } else {
            this.allUnits = result;
          }
          this.loading = false;
        });

        // This primarily exists to start the profile initialization
        // process.  Currently profile provider depends on correct
        // initialization of the DbProvider since we need
        this.profileProvider.getDB();
        this.profileProvider.getProfile().then(profile => {
          this.profile = profile;
          // if (!this.loading && this.profile.firstName && this.userInfo) {
          this.profileProvider.syncProfile(this.profile);
          // }
        });
      }
    });
  }
}
