import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, LoadingController, ToastController } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';
import { DbService } from 'src/app/services/db.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/models/profile';
import { StripeModalPage } from '../stripe-modal/stripe-modal.page';

@Component({
  selector: 'app-premium-version',
  templateUrl: './premium-version.page.html',
  styleUrls: ['./premium-version.page.scss'],
})
export class PremiumVersionPage {

  profile: Profile;
  color: string;
  userInfo: any;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    //public admob: AdMobPro,
    public dbProvider: DbService,
    private profileProvider: ProfileService,
    public modalCtrl: ModalController,
    public platform: Platform,
    //public iap: InAppPurchase,
    public loadCtrl: LoadingController,
    public toastCtrl: ToastController
  ) {
    if (navParams.get('color')) {
      this.color = navParams.get('color');
    } else {
      this.color = 'primary';
    }

    this.profileProvider.getProfile().then(stats => {
      this.profile = stats;
      //console.log(stats);
      //admob.removeBanner();
    });

    this.userInfo = localStorage.getItem('userInfo');
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad PremiumVersionPage');
  }

  /**
   * This doesn't seem to trigger after modal close.  Bug?
   */
  ionViewDidEnter() {
    console.log('ionViewDidEnter!');
    this.profileProvider.getProfile().then(stats => {
      this.profile = stats;
    });
  }

  goToHome() {
    this.navCtrl.navigateRoot('home');
  }

  async openModal() {
    console.log('Entering to the openModal');
    if (this.userInfo) {
      if (!this.platform.is('cordova')) {

        const modal = await this.modalCtrl.create({
          component: StripeModalPage,
          cssClass: 'stripe-modal'
        });
        // We need the following to ensure that once modal closes,
        // we can reload the profile object to confirm whether we are
        // in premium mode.
        modal.onDidDismiss().then(data => {
          if (data === 'success') {
            this.navCtrl.navigateRoot('home');
          }
          this.profileProvider.getProfile().then(stats => {
            this.profile = stats;
          });
        });
        modal.present();
      } else {
        this.buy();
      }
    } else {
      const modal = await this.modalCtrl.create({ component: StripeModalPage });
      // We need the following to ensure that once modal closes,
      // we can reload the profile object to confirm whether we are
      // in premium mode.
      modal.onDidDismiss().then(data => {
        if (data === 'success') {
          this.navCtrl.navigateRoot([HomePage]);
        }
        this.profileProvider.getProfile().then(stats => {
          this.profile = stats;
        });
      });
      modal.present();
    }
  }

  buy() {
    let productId: string;
    if (this.platform.is('android')) {
      productId = environment.ANDROID_PRODUCT_ID;
    } else if (this.platform.is('ios')) {
      productId = environment.IOS_PRODUCT_ID;
    }

    // this.iap.buy(productId).then(data => {
    //   let receipt = data.receipt;
    //   console.log(data);
    //   // iOS seems to give a base64 encoded receipt data from plugin
    //   if (this.platform.is('ios')) {
    //     receipt = btoa(receipt);
    //   }
    //   this.profile.updatedAt = new Date().getTime();
    //   this.profile.purchaseReceipt = data.receipt;
    //   this.profileProvider.setPremiumMode(this.profile);
    //   this.navCtrl.setRoot('LoginPage');
    // })
    //   .catch(err => {
    //     this.presentToast(JSON.stringify(err));
    //   });
  }

  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000
    });
    toast.present();
  }

}
