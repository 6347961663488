import {NgModule} from '@angular/core';
import {ProgressCircleComponent} from './progress-circle/progress-circle.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ProgressCircleComponent, AlertModalComponent],
  imports: [],
  exports: [ProgressCircleComponent, AlertModalComponent]
})
export class ComponentsModule {
}
