import { QuizStat } from './quiz_stat';

export class ExerciseStat {
  exerciseNumber: number; // The exercise number in the unit
  overallPercentage: number; // Total from the quizzes
  dateStarted: string;
  dateCompleted: string;
  totalCorrect: number;
  totalQuizzes: number;
  totalQuizzesCompleted: number;
  totalQuestions: number;
  numberOfRetries: number;
  timeTaken: number; // Number of milliseconds
  // Individual Quiz stats:
  quizStats: QuizStat[] = [];

  createdAt: number;

  static getStatForExercise(exerciseNumber: number, exerciseStats: ExerciseStat[]): ExerciseStat {
    return exerciseStats.filter(stat => stat.exerciseNumber === exerciseNumber)[0];
  }
}
