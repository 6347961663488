import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-more-info-modal',
  templateUrl: './more-info-modal.page.html',
  styleUrls: ['./more-info-modal.page.scss'],
})
export class MoreInfoModalPage {

  constructor(public navCtrl: NavController, public navParams: NavParams, private view: ModalController,) {
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad MoreInfoModalPage');
  }

  close() {
    this.view.dismiss();
  }

}
