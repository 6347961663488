import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Profile } from 'src/models/profile';
import { ProfileService } from './profile.service';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	profile: Profile;
	isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	private userInfo = new Subject<any>();

	constructor(private http: HttpClient,
		private utilsService: UtilsService,
		private profileService: ProfileService) { }

	loginProfileOnPortal(credentials: { email; password }, profileSyncUrl: string): Observable<any> {

		console.log('Login to portal');
		return this.http.post(profileSyncUrl + '/login', credentials, { withCredentials: true }).pipe(
			map(async (response: any) => {

				const profileResult = await this.profileService.getProfile();
				this.profile = profileResult;
				this.profile.email = response.email;
				this.profile.portalId = response.id;
				this.profile.password = credentials.password;
				this.profile.updatedAt = 1;
				const value = await this.profileService.syncProfile(this.profile);
				console.log('Data Received from Login', response);
				localStorage.setItem('userInfo', JSON.stringify(response));
				if (this.profile) {
					localStorage.setItem('profileFirstName', this.profile.firstName);
					localStorage.setItem('profileLastName', this.profile.lastName);
				}
				this.isAuthenticated.next(true);
				return response;
			}),
			catchError(this.utilsService.handleErrorData)
		);
	}

	logout(): Promise<void> {

		localStorage.clear();
		this.profileService.removeProfile();
		this.isAuthenticated.next(false);

		return Promise.resolve();
	}

	publishUserData(data: any) {
		this.userInfo.next(data);
	}

	getUserInfo(): Subject<any> {
		return this.userInfo;
	}
}
