import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UnitPage } from './pages/unit/unit.page';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'unit',
    component: UnitPage
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    // component: LoginPage
  },
  {
    path: 'premium-version',
    loadChildren: () => import('./pages/premium-version/premium-version.module').then(m => m.PremiumVersionPageModule)
  },
  {
    path: 'lesson',
    loadChildren: () => import('./pages/lesson/lesson.module').then( m => m.LessonPageModule)
  },
  {
    path: 'exercise',
    loadChildren: () => import('./pages/exercise/exercise.module').then( m => m.ExercisePageModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('./pages/quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'quiz/:quizId',
    loadChildren: () => import('./pages/quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'code-registration',
    loadChildren: () => import('./pages/code-registration/code-registration.module').then( m => m.CodeRegistrationPageModule)
  },
  {
    path: 'performance-summary',
    loadChildren: () => import('./pages/performance-summary/performance-summary.module').then( m => m.PerformanceSummaryPageModule)
  },
  {
    path: 'completion-avg',
    loadChildren: () => import('./pages/completion-avg/completion-avg.module').then( m => m.CompletionAvgPageModule)
  },
  {
    path: 'reflection',
    loadChildren: () => import('./pages/reflection/reflection.module').then( m => m.ReflectionPageModule)
  },
  // {
  //   path: 'change-password',
  //   loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  // },
  // {
  //   path: 'class-registration',
  //   loadChildren: () => import('./pages/class-registration/class-registration.module').then( m => m.ClassRegistrationPageModule)
  // },

  // {
  //   path: 'forgot-password',
  //   loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  // },
  // {
  //   path: 'glossary',
  //   loadChildren: () => import('./pages/glossary/glossary.module').then( m => m.GlossaryPageModule)
  // },
  // {
  //   path: 'info',
  //   loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule)
  // },
  {
    path: 'flag/:uN/:lN/:eN/:iN',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  // },

  // {
  //   path: 'quiz',
  //   loadChildren: () => import('./pages/quiz/quiz.module').then( m => m.QuizPageModule)
  // },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  // {
  //   path: 'stripe-modal',
  //   loadChildren: () => import('./pages/stripe-modal/stripe-modal.module').then( m => m.StripeModalPageModule)
  // },
  // {
  //   path: 'table-contents',
  //   loadChildren: () => import('./pages/table-contents/table-contents.module').then( m => m.TableContentsPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
