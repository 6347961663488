import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, interval, Observable, Subscription, timer } from 'rxjs';
import 'rxjs/add/observable/interval';
import { map, takeUntil, takeWhile } from 'rxjs/operators';
import { ChooseComponent } from 'src/app/pages/quiz/type/choose/choose.component';
import { QuizComponent } from 'src/app/pages/quiz/type/quiz.component';

/**
 * This is utility for timeout events in quizes
 */

const STORE_KEY = 'userLastAction';

@Injectable()
export class TimeoutUtility implements OnDestroy {

  static isAlertPresent = false;
  // Javascript Timeout object
  timeout;
  /* Amount of time student may be away from device/quiz
      * We want to allow a break here and this limbo time should be
      * take out from the total time for the exercise */
  limboTime: number; // in seconds
  intervalEmitter: Observable<number>;
  internalInterval: Observable<number>;
  intervalSubscriber: Subscription;
  inLimbo = false;

  public static testing: number = 0;
  public static runTimer: boolean;
  public static runSecondTimer: boolean;
  public USER_IDLE_TIMER_VALUE_IN_MIN: number = 3;
  public FINAL_LEVEL_TIMER_VALUE_IN_MIN: number = 1;
  public userIdlenessChecker: BehaviorSubject<string>;
  private readonly userActivityChangeCallback: ($event) => void = ($event) => this.handleUserActiveState($event);
  private sessionForIdle: Observable<number>;
  private sessionForIdleSubscription: Subscription;
  public secondLevelUserIdleChecker: BehaviorSubject<string>;
  public clockForIdle: Observable<number>;

  constructor(private zone: NgZone) {

    console.log('TimeoutUtility', TimeoutUtility.testing);
    TimeoutUtility.testing++;

    if (!this.userIdlenessChecker) {
      this.userIdlenessChecker = new BehaviorSubject<string>('INITIATE_TIMER');
    }

    if (!this.secondLevelUserIdleChecker) {
      this.secondLevelUserIdleChecker = new BehaviorSubject<string>('INITIATE_SECOND_TIMER');
    }

    this.limboTime = 0;
    this.internalInterval = interval(1000);
    // this.intervalEmitter = Observable.interval(1000).filter(() => this.inLimbo);

    this.intervalSubscriber = this.internalInterval.subscribe(
      () => {
        this.limboTime++;
        //console.log('limbo: ' + this.limboTime);
      },
      e => console.log(e),
      () => console.log('interval subscription ended')
    );
  }

  ngOnDestroy(): void {

    console.log('TimeoutUtility Calling to the ngOnDestroy');

    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.unsubscribe();
      this.userIdlenessChecker = undefined;
    }

    if (this.secondLevelUserIdleChecker) {
      this.secondLevelUserIdleChecker.unsubscribe();
      this.secondLevelUserIdleChecker = undefined;
    }
  }

  /*
   * Let's setup timeouts...
   * If user doesn't take action within 5 minutes, display
   * "continue?" dialog.  If 30 seconds passes and user doesn't
   * respond to the "continue?" dialog, then pause quiz timers.
   */
  public setupOrUpdateTimeouts(context: QuizComponent | ChooseComponent) {
    const fiveMinutes = 0.5 * 60 * 1000; // into milliseconds
    this.clear();
    this.timeout = setTimeout(async () => {
      const thirtySeconds = 30 * 1000; // into milli
      // If there is no response, then we should wait until 30 seconds
      // and start limbo counter to ensure that we don't count the
      // time user is away from the quiz/device
      const backTimer = setTimeout(() => {
        //alert.dismiss();
        this.inLimbo = true;
      }, thirtySeconds);



      if (!TimeoutUtility.isAlertPresent) {
        //alert.present();
        TimeoutUtility.isAlertPresent = true;
      }

    }, fiveMinutes);

  }

  public clear() {
    this.inLimbo = false;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public dispose() {
    this.clear();
    const internalTimer = timer(1);
    // Let's end the observable:
    this.internalInterval.pipe(takeUntil(internalTimer));
    if (this.intervalSubscriber) {
      this.intervalSubscriber.unsubscribe();
      this.intervalSubscriber = undefined;
    }
  }

  /// NEW IMPLEMENTATION

  get lastAction(): number {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  public initilizeSessionTimeout(): void {
    TimeoutUtility.runTimer = true;

    if (this.USER_IDLE_TIMER_VALUE_IN_MIN === 0) {
      this.userIdlenessChecker.thrownError('Please provide USER_IDLE_TIMER_VALUE in minuite');
      return;
    }

    this.resetMainTimer();
    this.initListener();
    this.initInterval();
  }

  private initListener(): void {
    console.log('TimeoutUtility - initListener');
    this.zone.runOutsideAngular(() => {

      window.document.addEventListener('click', this.userActivityChangeCallback, true);
    });
  }

  removeListener(): void {
    this.zone.runOutsideAngular(() => {
      window.document.removeEventListener('click', this.userActivityChangeCallback, true);
    });
  }

  handleUserActiveState(event): void {
    console.log('handleUserActiveState - onclick is working');
    this.resetMainTimer();
  }

  private executeFinalTimer = () => {
    TimeoutUtility.runSecondTimer = true;
    this.initializeFinalTimer();
  }

  private initInterval(): void {
    const intervalDuration = 1000;
    this.sessionForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        // console.log('First Interval tick : ', tick);
        return tick;
      }),
      takeWhile(() => TimeoutUtility.runTimer)
    );

    this.check();
  }

  private initializeFinalTimer(): void {
    const intervalDuration = 1000;
    this.clockForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        console.log('Final Interval tick : ', tick);
        return tick;
      }),
      takeWhile(() => TimeoutUtility.runSecondTimer)
    );

    this.checkUserActionTime();
  }

  private check(): void {
    // if (this.sessionForIdleSubscription) {
    //   this.se
    // }
    this.sessionForIdleSubscription = this.sessionForIdle.subscribe(() => {
      // console.log('Entering to the check that is executed every second');
      const now = Date.now();
      const timeleft = this.lastAction + this.USER_IDLE_TIMER_VALUE_IN_MIN * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;

      this.userIdlenessChecker.next(`${diff}`);

      if (isTimeout) {
        console.log('Entering por el timeout, deberia remover el evento');
        window.document.removeEventListener('click', this.userActivityChangeCallback, true);
        this.zone.run(() => {

          if (this.userIdlenessChecker) {
            this.userIdlenessChecker.next('STOPPED_TIMER');

            if (this.FINAL_LEVEL_TIMER_VALUE_IN_MIN > 0) {
              this.secondLevelUserIdleChecker.next('SECOND_TIMER_STARTED');
              this.executeFinalTimer();
            }
          }
          TimeoutUtility.runTimer = false;
        });
      }
    });
  }

  private checkUserActionTime(): void {

    let timeInSecond = 60 * this.FINAL_LEVEL_TIMER_VALUE_IN_MIN;
    let timeInMin = this.FINAL_LEVEL_TIMER_VALUE_IN_MIN - 1;
    this.clockForIdle.subscribe(() => {

      const now = Date.now();
      const timeleft = this.lastAction + this.FINAL_LEVEL_TIMER_VALUE_IN_MIN * 60 * 1000;
      const diff = timeleft - now;

      if (this.secondLevelUserIdleChecker) {
        this.secondLevelUserIdleChecker.next(`${diff}`);
      }

      if (--timeInSecond === 0) {
        if (--timeInMin === 0) {
          timeInMin = (timeInMin > 0) ? (timeInMin - 1) : 0;
        }
        if (timeInMin === -1 && timeInSecond === 0) {
          TimeoutUtility.runSecondTimer = false;
          if (this.secondLevelUserIdleChecker) {
            this.secondLevelUserIdleChecker.next('SECOND_TIMER_STOPPED');
          }
        }
        if (timeInMin < 0) {
          timeInMin = 0;
          setTimeout(() => {
            timeInSecond = 60;
          }, 800);
        } else {
          timeInSecond = 60;
        }
      }
    });
  }

  public resetMainTimer(): void {
    console.log('TimeoutUtility - resetMainTimer');
    this.lastAction = Date.now();

    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.next('RESET_TIMER');
    }
    if (this.secondLevelUserIdleChecker) {
      this.secondLevelUserIdleChecker.next('RESET_FIRST_TIMER');
    }
  }

  public resetFinalTimer(): void {
    console.log('TimeoutUtility - resetFinalTimer');
    if (this.secondLevelUserIdleChecker) {
      this.secondLevelUserIdleChecker.next('RESET_FIRST_TIMER');
    }
  }
}
