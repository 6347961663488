import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavController, NavParams, ToastController, Platform, ModalController, IonicSlides } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DbService } from 'src/app/services/db.service';
import { HttpService } from 'src/app/services/http.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SailsResponse } from 'src/app/services/sails-response';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/models/profile';

import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Stripe: any;

@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.page.html',
  styleUrls: ['./stripe-modal.page.scss'],
})
export class StripeModalPage implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('cardelement') cardInfo!: any;

  slides: any;
  loading = false;
  cardHandler = this.onChange.bind(this);
  index = 1;
  passwordType = 'password';
  passwordIcon = 'eye-off';
  passwordType1 = 'password';
  passwordIcon1 = 'eye-off';
  state: any;
  countries: any;
  stripe: any;
  card: any;
  error: string;
  submitted = false;
  profile: Profile;
  user: any = {
    firstName: '',
    email: '',
    cemail: '',
    password: '',
    cpassword: '',
    nativeLanguage: '',
    ageRange: ''
  };
  user_paymnet: any = {
    address_state: '',
    address_country: 'United States'
  };
  user_login: any = {
    email: '',
    password: ''
  };
  EMAIL_VALID_CHECK = /^.+@.+\..+$/;
  userInfo: any;
  submitClicked = false;

  letter = /[a-z]/;
  upper = /[A-Z]/;
  number_pattern = /[0-9]/;
  changePass: boolean;
  changePassN: boolean;
  changePassU: boolean;
  lang: any;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public toastCtrl: ToastController,
    private httpData: HttpService,
    private authService: AuthenticationService,
    private profileProvider: ProfileService,
    public platform: Platform,
    //public iap: InAppPurchase,
    public util: UtilsService,
    elementRef: ElementRef
    //public events: Events
  ) {
    profileProvider.getProfile().then(profile => {
      this.profile = profile;
    });
    this.userInfo = localStorage.getItem('userInfo');
  }

  ngOnInit(): void {
    this.getCountryData();
    this.getUSStateData();
  }

  setSwiperInstance(swiper: any) {
    this.slides = swiper;
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad StripeModalPage');


    this.getLanguage();

    // if (this.userInfo && this.slides) {
    //   this.slides.slideTo(3);
    //   // this.slides.lockSwipeToPrev(false);
    //   // this.slides.lockSwipeToNext(false);
    // } else {
    this.slides.allowSlideNext = false;
    // }

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.getInAppProducts();
        if (this.userInfo) {
          this.buy();
          this.viewCtrl.dismiss();
        }
      }
    });
  }

  termsClick() {
    window.open(
      'https://www.languageartspress.com/terms',
      '_system',
      'location=yes'
    );
  }

  privacyClick() {
    window.open(
      'https://www.languageartspress.com/privacy',
      '_system',
      'location=yes'
    );
  }

  changePassword() {
    this.changePass = this.letter.test(this.user_login.password);
    this.changePassU = this.upper.test(this.user_login.password);
    this.changePassN = this.number_pattern.test(this.user_login.password);
  }

  changePasswordSignup() {
    this.changePass = this.letter.test(this.user.password);
    this.changePassU = this.upper.test(this.user.password);
    this.changePassN = this.number_pattern.test(this.user.password);
  }

  getInAppProducts() {
    let productId: string;
    if (this.platform.is('android')) {
      productId = environment.ANDROID_PRODUCT_ID;
    } else if (this.platform.is('ios')) {
      productId = environment.IOS_PRODUCT_ID;
    }

    // this.iap
    //   .getProducts([productId])
    //   .then(products => {
    //     console.log('Products:' + JSON.stringify(products));
    //   })
    //   .catch(err => {
    //     console.log(JSON.stringify(err));
    //   });
  }

  buy() {
    let productId: string;
    if (this.platform.is('android')) {
      productId = environment.ANDROID_PRODUCT_ID;
    } else if (this.platform.is('ios')) {
      productId = environment.IOS_PRODUCT_ID;
    }

    // this.iap
    //   .buy(productId)
    //   .then(data => {
    //     let receipt = data.receipt;
    //     console.log(data);
    //     // iOS seems to give a base64 encoded receipt data from plugin
    //     if (this.platform.is('ios')) {
    //       receipt = btoa(receipt);
    //     }
    //     this.profile.updatedAt = new Date().getTime();
    //     this.profile.purchaseReceipt = data.receipt;
    //     this.profileProvider.setPremiumMode(this.profile);

    //     this.slides.lockSwipeToNext(false);
    //     this.slides.slideTo(4);
    //     this.slides.lockSwipeToNext(true);
    //     this.slides.lockSwipeToPrev(true);
    //   })
    //   .catch(err => {
    //     this.presentToast(JSON.stringify(err));
    //   });
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  hideShowPassword1() {
    this.passwordType1 = this.passwordType1 === 'text' ? 'password' : 'text';
    this.passwordIcon1 = this.passwordIcon1 === 'eye-off' ? 'eye' : 'eye-off';
  }

  redirectToCreateAccount() {
    this.close('redirecting to Create Account');
    this.navCtrl.navigateRoot('signup');
  }

  redirectToLogIn() {
    this.close('redirecting to Create Account');
    this.navCtrl.navigateRoot('login');
  }

  slideChanged() {
    this.index = this.slides.activeIndex + 1;
    console.log('Current index is', this.index);
    this.changePass = false;
    this.changePassU = false;
    this.changePassN = false;
  }

  slidePrev() {
    this.changePass = false;
    this.changePassU = false;
    this.changePassN = false;

    this.slides.slidePrev();
  }

  slidePrevLogin() {
    this.changePass = false;
    this.changePassU = false;
    this.changePassN = false;
    this.user_login = { email: '', password: '' };
    console.log(this.user_login);

    this.slides.lockSwipeToPrev(false);
    this.slides.slideTo(0);
    this.slides.lockSwipeToNext(true);
  }

  gotoHome() {
    this.close('success');
    this.navCtrl.navigateRoot('home');
  }

  async singupClick() {
    this.navCtrl.navigateRoot('signup');
  }

  loginClick() {
    console.log('STRIPEEEEEEEEEEEEEMODAL - loginClick');
    this.submitClicked = true;
    if (!this.user_login.email) {
      this.util.presentToast('Please enter email');
    } else if (!this.EMAIL_VALID_CHECK.test(this.user_login.email)) {
      this.util.presentToast('please enter valid email.');
    }
    //  else if (!this.user_login.password) {
    //   this.util.presentToast("Please enter password");
    // } else if (this.user_login.password.length < 8) {
    //   this.util.presentToast("Password must be at least 8 characters");
    // }
    else {
      let res: any;
      this.authService.loginProfileOnPortal(this.user_login, DbService.profileSyncUrl)
        .subscribe(
          response => {
            res = response;
            this.profileProvider.getProfile().then(profile => {
              this.profile = profile;
              this.profile.firstName = res.firstName;
              this.profile.lastName = res.lastName;
              this.profile.email = res.email;
              this.profile.password = this.user_login.password;
              this.profile.portalId = res.id;

              this.profileProvider.syncProfile(this.profile);
            });
            localStorage.setItem('userInfo', JSON.stringify(response));
            //this.events.publish('user:created', 'user', Date.now());
            this.close('success');
          },
          error => {
            console.log('Login:oops', error);
            if (error === undefined) {
              this.util.presentToast(
                'Failed to communicate with server.  Please try again later.'
              );
            } else {
              this.util.presentToast(error);
            }
          }
        );
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.cardInfo === undefined) {
        console.log('Card info not found');
      } else {
        console.log('this.cardInfo.nativeElement', this.cardInfo);
        console.log('ngAfterViewInit');
        this.stripe = Stripe(environment.STRIPE_ID);
        const elements = this.stripe.elements();
        this.card = elements.create('card', {
          hidePostalCode: true,
          style: {
            base: {
              iconColor: '#666EE8',
              color: '#31325F',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: 'Helvetica Neue',
              fontSize: '15px',
              '::placeholder': {
                color: '#CFD7E0'
              }
            }
          }
        });
        console.log('this.cardInfo.nativeElement', this.cardInfo);
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
      }
    }, 5);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
  }

  onSubmit(form: NgForm) {
    this.loading = true;
    const options = {
      address_state: this.user_paymnet.address_state,
      address_country: this.user_paymnet.address_country
    };
    if (!options.address_country) {
      this.presentToast('Please select your Country');
    } else if (
      !options.address_state &&
      options.address_country === 'United States'
    ) {
      this.presentToast('Please select the state');
    } else {
      this.submitted = true;
      this.stripe.createToken(this.card, options).then(result => {
        const error = result.error;
        const token = result.token;
        if (error) {
          this.submitted = false;
          this.presentToast(error.message);
          console.log('Something is wrong:', error);
        } else {
          console.log('Attempting to capture token');
          this.httpData.capturePayment(
            token.id,
            DbService.paymentUrl,
            DbService.paymentAmount,
            DbService.paymentCurrency
          )
            .subscribe(
              async (response: SailsResponse<boolean>) => {
                console.log(response);
                // this.close("success");
                if (response.result) {
                  this.profile.updatedAt = null;
                  this.profile.purchaseReceipt = '{"transactionId": "' + response.message + '"}';
                  this.profile.premiumMode = true;
                  this.profile.timeOfPurchase = new Date().toJSON();
                  //this.profileProvider.setPremiumMode(this.profile);
                  console.log('capturePayment - Profile to be updated', this.profile);
                  const resultingProfile = await this.profileProvider.syncProfile(this.profile);
                  //this.profileProvider.syncProfile(this.profile);
                  this.presentToast('Your payment has been processed successfully');
                  console.log('this.slides', this.profile);
                  this.loading = false;
                  this.slides.allowSlideNext = true;
                  this.slides.slideNext();
                  this.slides.allowSlideNext = false;
                } else {
                  this.presentToast(response.message);
                }
              },
              errorPayment => {
                this.loading = false;
                console.log('oops', errorPayment);
                this.presentToast(errorPayment.message);
                this.submitted = false;
              }
            );
        }
      });
    }
  }

  getLanguage() {
    this.httpData.getLanguages().subscribe(res => {
      this.lang = res;
    });
  }

  getCountryData() {
    this.httpData.getCountryData().subscribe(res => {
      console.log('Obtaining countries', res);
      this.user_paymnet.address_country = 'United States';
      this.countries = res;
    });
  }

  getUSStateData() {
    this.httpData.getUSStateData().subscribe(res => {
      this.state = res;
    });
  }

  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000
    });
    toast.present();
  }

  close(success) {
    this.viewCtrl.dismiss(success);
  }

}
