import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { HomePage } from './home/home.page';
import { InfoPage } from './pages/info/info.page';
import { HelpInfoComponent } from './pages/lesson/help-info/help-info.component';
import { TableContentsPage } from './pages/table-contents/table-contents.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuizPageModule } from './pages/quiz/quiz.module';
import { ComponentsModule } from './components/components.module';
import { PerformanceSummaryPageModule } from './pages/performance-summary/performance-summary.module';
import { ClassRegistrationPageModule } from './pages/class-registration/class-registration.module';
import { ExercisePageModule } from './pages/exercise/exercise.module';
import { GlossaryPageModule } from './pages/glossary/glossary.module';
import { LessonPageModule } from './pages/lesson/lesson.module';
import { UnitPageModule } from './pages/unit/unit.module';
import { PremiumVersionPageModule } from './pages/premium-version/premium-version.module';
import { CodeRegistrationPageModule } from './pages/code-registration/code-registration.module';
import { LoginPageModule } from './pages/login/login.module';
import { CompletionAvgPageModule } from './pages/completion-avg/completion-avg.module';
import { DbService } from './services/db.service';
import { ProfileService } from './services/profile.service';
import { HttpService } from './services/http.service';
import { UtilsService } from './services/utils.service';
import { CommonModule } from '@angular/common';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { StripeModalPage } from './pages/stripe-modal/stripe-modal.page';
import { SwiperModule } from 'swiper/angular';
import { UnitPage } from './pages/unit/unit.page';
import { TimeoutUtility } from './services/timeout_utility';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    InfoPage,
    HelpInfoComponent,
    TableContentsPage,
    ForgotPasswordPage,
    StripeModalPage,
    UnitPage
  ],
  imports: [
    FormsModule,
    SwiperModule,
    ComponentsModule,
    // QuizPageModule,
    // ComponentsModule,
    // PerformanceSummaryPageModule,
    // ClassRegistrationPageModule,
    // ExercisePageModule,
    // GlossaryPageModule,
    // LessonPageModule,
    // UnitPageModule,
    // PremiumVersionPageModule,
    // CodeRegistrationPageModule,
    // LoginPageModule,
    // CompletionAvgPageModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule],
  providers: [
    StatusBar,
    SplashScreen,
    GoogleAnalytics,
    DbService,
    ProfileService,
    HttpService,
    TimeoutUtility,
    UtilsService,
    NavParams,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
