import { CourseProduct } from './course_product';
import { LessonStat } from './lesson_stat';

export class Profile {
  _id: string;
  _rev: string;
  /**
   * Id of the Student model from the Portal.
   * CREATE REST request returns the ID from portal.
   * We will use this for our Get requests.  It should also be possible to
   * get the Student profile from the portal using email
   * since that will be necessary for account creation and login.
   */
  portalId: string;
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  institution: string;
  birthDate: string;
  appCodes: string[];
  nativeLanguage: string;
  lessonStats: LessonStat[] = [];
  premiumMode: boolean;
  remarketingAdsShown: number[];
  timeOfPurchase: string;
  courseProduct: CourseProduct[] = [];
  /*
  purchaseReceipt would have the following data:
    Array<{"orderId": 'string', "packageName": 'string',"productId": 'string',
      "purchaseTime": 'string', "purchaseState": 'string', "purchaseToken": 'string'}>;
   */
  purchaseReceipt: string;
  updatedAt: number;
}
