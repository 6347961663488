import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import { Lesson } from 'src/models/lesson';
import { Unit } from 'src/models/unit';
import { LessonPage } from '../lesson/lesson.page';

declare let ga;

@Component({
  selector: 'app-unit',
  templateUrl: './unit.page.html',
  styleUrls: ['./unit.page.scss'],
})
export class UnitPage {

  unit: Unit;
  lessons: Lesson[];
  units: any;
  navParams: any;

  mainTitle: string = environment.APP_NAME;
  lessonsProgress: number[] = [];
  slides: any;

  constructor(public navCtrl: NavController,
    private router: Router,
    private platform: Platform,
    private profileProvider: ProfileService,
    public gaNative: GoogleAnalytics) {

    console.log('constructor UnitPage');
    this.navParams = this.router.getCurrentNavigation().extras.state;
    if (this.navParams == null) {
      this.navCtrl.navigateRoot('home');
    }
    this.unit = this.navParams.unit;
    //this.lessons = this.navParams.get('lesson');
    this.lessons = this.unit.lessons;
    this.lessons.forEach(l => this.lessonsProgress.push(0)); // assume no progress

    this.loadLessonProgress();

    /*unitService.getUnit(navParams.get("id"))
      .then(unit => {
        console.log(unit);
        if (unit) {
          this.unit = unit;
          // Let's load the Unit's Lessons
          this.lessons = this.unit.getLessons();
        }
      });*/
    if (!this.platform.is('cordova')) {
      ga('set', 'page', 'Unit page');
      ga('send', 'pageview');
    }
  }

  ionViewCanEnter(): Promise<boolean> {
    return this.loadLessonProgress().then(r => Promise.resolve(true));
  }

  ionViewDidEnter() {
    //this.loadLessonProgress();
  }

  setSwiperInstance(swiper: any) {
    console.log('Setting swiper refercen');
    this.slides = swiper;
  }

  goBack() {
    this.navCtrl.pop();
  }

  goToHome() {
    this.navCtrl.navigateRoot(['home']);
  }

  public openLesson(lesson: Lesson): void {

    if (this.platform.is('cordova')) {

      this.gaNative
        .trackEvent('Lesson', 'Click', 'Lesson ' + lesson.number, 1)
        .then(() => { })
        .catch(e => console.log('Error tracking GoogleAnalytics', e));
    } else {
      ga('send', 'event', {
        eventCategory: 'Lesson',
        eventAction: 'Click',
        eventValue: 1,
        eventLabel: 'Lesson ' + lesson.number
      });
    }

    console.log('unit to be send', this.unit);

    //this.navCtrl.push(LessonPage,{'lesson' : lesson , 'unit':this.unit})
    this.navCtrl.navigateForward(['lesson'], {
      state: {
        lesson: lesson.number, unit: this.unit
        //callback: this.currentSlideCallBack
      }
    });

  }

  /**
   * Callback to help the lesson exercise pass the current slide number to this unit page. So that
   * we can ensure that the right slide is shown if the lesson slides have progressed using a
   * swipe.
   *
   * @param _params the index of the lessons array list of this Unit
   * @returns
   */
  currentSlideCallBack = (slideNumber: number) => new Promise<void>((resolve, reject) => {
    if (slideNumber !== undefined && slideNumber > -1) {
      this.slides.slideTo(slideNumber);
    }
    resolve();
  });

  loadLessonProgress() {
    return this.profileProvider
      .exercisesCompletedPercent(this.unit)
      .then(result => {
        result.forEach((r, index) => (this.lessonsProgress[index] = r));
      });
  }

  /**
   * return the spacebetween slides in px based on the width of the current slide in px
   *
   * @param theSlide
   */
  spaceBetweenSlides(): number {
    const width: number = this.platform.width();
    let division = -4;
    if (width <= 451) {
      division = 5;
    } else if (width <= 570) {
      division = -6;
    } else if (width <= 990) {
      division = -3;
    } else if (width <= 2000) {
      division = -2.5;
    } else {
      division = -2.2;
    }
    return Math.round(width / division);
  }

  slidePrev() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }

  isLeftArrowTransparent() {
    if (this.slides) {
      return this.slides.isBeginning;
    }
    return false;
  }

  isRightArrowTransparent() {
    if (this.slides) {
      return this.slides.isEnd;
    }
    return false;
  }

}
