import {ExerciseStat} from './exercise_stat';

export class LessonStat {
  _id: string;
  _rev: string;
  
  id: string;
  unitId: string;
  lessonNumber: number;
  confidenceLevel: number;
  completionPercent: number;
  overallPercentage: number;
  dateStarted: string;
  dateCompleted: string;
  timeTaken: number; // number of milliseconds
  totalCorrect: number;
  totalQuestions: number;
  numberOfRetries: number;
  numberOfExercises: number;
  exerciseStats: ExerciseStat[] = [];
  /*
    The following variable is for mapping to the Product table in
    the backend.  This is name of the application product: The Grammar You Need 1,
    The Grammar You Need 2, etc.  It helps ensure that we know to which TGYN app
    this lesson belongs.
   */
  product: { name: string };
}
