/* eslint-disable no-underscore-dangle */

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Answer } from 'src/models/answer';
import { Exercise } from 'src/models/exercise';
import { Lesson } from 'src/models/lesson';
import { Quiz } from 'src/models/quiz';
import { Remarketing } from 'src/models/remarketing';
import { Unit } from 'src/models/unit';
//import * as PouchDB from 'pouchdb';
import PouchDB from 'pouchdb';



@Injectable({
  providedIn: 'root'
})
export class DbService {

  static paymentUrl: string;
  static paymentCurrency = 'USD';
  static paymentAmount: number;
  static profileSyncUrl: string;
  static studentPortalUrl: string;
  BASEURL: string = environment.BASEURL + '/student';
  db;
  remoteDB;
  success: boolean;
  liveSync: any;

  constructor(private toastController: ToastController) {
    // Let the app init run DB initialization logic!
  }

  getDB() {
    if (this.success === undefined) {
      this.success = true;
    }
    if (this.db === undefined) {
      this.db = new PouchDB(environment.DB_NAME);
    }
    return this.db;
  }

  getRemoteDB() {
    if (this.remoteDB === undefined) {
      this.remoteDB = new PouchDB(environment.REMOTE_SYNC_URL, {
        auth: {
          username: environment.REMOTE_USER,
          password: environment.REMOTE_PASS
        }
      });
    }
    return this.remoteDB;
  }

  initializeDB(): Promise<any> {
    // Let's do a one-time sync - needed for the browser to
    // refresh correctly on first load.
    //var sync = PouchDB.sync(ENV['DB_NAME'], ENV['REMOTE_SYNC_URL'])
    // do one way, one-off sync from the server until completion
    return this.getDB()
      .replicate.from(environment.REMOTE_SYNC_URL, {
        live: false,
        retry: true, // doesn't work if live is not true.
        auth: {
          username: environment.REMOTE_USER,
          password: environment.REMOTE_PASS
        }
      })
      .on('error', (err) => {
        console.log('Error loading data from Cloudant remote server');
        console.log(err);
      })
      .then(result => {
        console.log(result);
        this.liveSync = result;
        this.getPaymentDetails().then((paymentInfo: any) => {
          console.log(paymentInfo);
          if (paymentInfo.length !== 0) {
            DbService.paymentCurrency = paymentInfo.currency;
            DbService.paymentAmount = paymentInfo.amount;
          }

          DbService.paymentUrl = environment.PAYMENT_URL;
          DbService.profileSyncUrl = environment.PROFILE_SYNC_URL;
          DbService.studentPortalUrl = environment.STUDENT_PORTAL_URL;
          // DbService.paymentUrl = 'http://localhost:1337/stripe/tgyn';
          // DbService.profileSyncUrl = 'http://localhost:1337/student';
          // DbService.studentPortalUrl = 'http://localhost:1337';
        });
        return result;
      })
      .catch(e => console.log(e));
  }

  addUnit(unit: Unit): Promise<boolean> {
    return new Promise(resolve => {
      this.getDB()
        .put(unit)
        .then(() => {
          //console.log('added a Unit successfully.');
        })
        .catch(err => {
          this.success = false;
          console.log('could not add unit: ' + err);
          this.toastController.create({
            message: 'Could not add unit.',
            duration: 1000
          });
        });
      resolve(true);
    });
  }

  /**
   * Only use this after the DB is in the "initialized" state.
   *
   * @returns Unit
   */
  getUnits(): Promise<Unit[]> {
    return this.getDB().allDocs({
      include_docs: true,
      startkey: 'unit01',
      endkey: 'unit\ufff0'
    })
      .then(async result => Promise.all(result.rows.map(row => row.doc).map(async (unit: Unit) => {
        unit.lessons = await Promise.all(unit.lessons.map(async (lesson: Lesson) => {
          lesson.exercises = await Promise.all(lesson.exercises.map(async (exercise: any) => {
            exercise.quizes = await this.getQuizes(exercise.quizes);
            return exercise;
          }));
          return lesson;
        }));
        return unit;
      })))
      .catch(() => {
        this.toastController.create({
          message: 'Sorry, could not load units.',
          duration: 1000
        });
      });
  }

  getRemoteUnits(): Promise<Unit[]> {
    return this.getRemoteDB().allDocs({
      include_docs: true,
      startkey: 'unit01',
      endkey: 'unit\ufff0'
    })
      .then(result => result.rows.map(row => row.doc).map((unit: Unit) => {
        unit.lessons.map((lesson: Lesson) => {
          lesson.exercises.map(() => {
            // this.getRemoteQuizes(exercise["quizes"]).then(
            //   result => (exercise["quizes"] = result)
            // );
          });
        });
        return unit;
      }))
      .catch(() => {
        this.toastController.create({
          message: 'Sorry, could not load units.',
          duration: 1000
        });
      });
  }

  getRemoteUnit(unitId: string): Promise<Unit[]> {
    return this.getRemoteDB().allDocs({
      include_docs: true,
      keys: [unitId]
    })
      .then(async result =>
        //console.log("getUnits() :", JSON.stringify(result));
        await Promise.all(result.rows.map(row => row.doc).map(async (unit: Unit) => {
          let allQuizes: string[] = [];
          unit.lessons.forEach(async (lesson: Lesson) => {
            lesson.exercises.forEach((exercise: any) => {
              allQuizes = exercise.quizes.concat(allQuizes);
            });
          });
          //console.log("allQuizes", JSON.stringify(allQuizes));
          const allQuizesExpanded = await this.getRemoteQuizes(allQuizes);
          //console.log("allQuizesExpanded", JSON.stringify(allQuizesExpanded));
          unit.lessons.forEach((lesson: Lesson) => {
            lesson.exercises.forEach((exercise: any) => {
              exercise.quizes = exercise.quizes.map(quiz => {
                const test = allQuizesExpanded.find(quizExpanded => quizExpanded._id === quiz);
                return test;
              });
            });
          });

          return unit;
        })
        )
      )
      .catch(() => {
        this.toastController.create({
          message: 'Sorry, could not load units.',
          duration: 1000
        });
      });
  }

  /**
   * Helps retrieve a particular unit from the DB.
   *
   * @param unitId the unit's _id field for retrieval
   */
  getUnit(unitId: string): Promise<Unit | null> {
    return this.getDB()
      .allDocs({
        include_docs: true,
        keys: [unitId]
      })
      .then(result => {
        //console.log(result.rows[0]);
        if (result.rows.length === 0) {
          return null; // not found!
        }

        const units: Unit[] = result.rows
          .map(row => row.doc)
          .map((unit: Unit) => {
            unit.lessons.map((lesson: Lesson) => {
              lesson.exercises.map((exercise: any) => {
                this.getQuizes(exercise.quizes).then(
                  returnedQuiz => (exercise.quizes = returnedQuiz)
                );
              });
            });
            return unit;
          });

        return units[0]; // One and only one
      })
      .catch(() => {
        this.toastController.create({
          message: 'Sorry, could not load the unit.',
          duration: 1000
        });
      });
  }

  getRemarketingAds(): Promise<Remarketing[]> {
    return this.getDB()
      .allDocs({
        include_docs: true,
        keys: ['remarketing-ads']
      })
      .then(result => result.rows[0].doc.remarketing)
      .catch(() => {
        this.toastController.create({
          message: 'Sorry, could not load ads',
          duration: 1000
        });
      });
  }

  getQuizes(quizIds: string[]): Promise<Quiz[]> {
    //console.log("Getting Quizes " + quizIds);
    return this.getDB()
      .allDocs({
        include_docs: true,
        keys: quizIds
      })
      .catch(err => {
        this.toastController.create({
          message: 'Sorry, could not load units.',
          duration: 1000
        });
      })
      .then(result => this.getQuizesFromPouchResult(result));
  }

  getRemoteQuizes(quizIds: string[]): Promise<Quiz[]> {
    return this.getRemoteDB()
      .allDocs({
        include_docs: true,
        keys: quizIds
      })
      .catch(err => {
        this.toastController.create({
          message: 'Sorry, could not load units.',
          duration: 1000
        });
      })
      .then(result => this.getQuizesFromPouchResult(result));
  }

  /**
   * This gets us the payment details - amount, currency, the URL for payment capture
   *
   * @returns PaymentInfo
   */
  getPaymentDetails(): Promise<Array<{ amount: number; currency: string; url: string; createProfileUrl: string }>> {
    return this.getDB().allDocs({
      include_docs: true,
      keys: ['payment-info']
    })
      .then(result => {
        if (result.rows.length === 0) {
          return null; // not found!
        }
        return result.rows[0].doc;
      })
      .catch(() => {
        this.toastController.create({
          message: 'Sorry, could not load payment information.',
          duration: 1000
        });
      });
  }

  getLessonFromQuizAndUnit(quiz: Quiz, unit: Unit): Lesson | null {
    return unit.lessons.reduce((acc: Lesson, cur: Lesson): Lesson => {
      const exercise = cur.exercises.reduce(
        (accExercise: Exercise, curExercise: Exercise): Exercise => {
          if (curExercise.quizes.indexOf(quiz) >= 0) {
            return curExercise;
          }
          if (accExercise) {
            return accExercise;
          }
          return null;
        },
        null
      );
      if (exercise) {
        acc = cur;
      }
      if (acc) {
        return acc;
      } else {
        return null;
      }
    }, null);
  }

  /**
   *
   * @param unit lesson is under this unit.  You can retrieve unit
   *              using the getUnit(unitId) method above.
   * @param lessonNumber the lesson number for this unit to retrieve.
   */
  getLessonFromUnit(unit: Unit, lessonNumber: number): Lesson | null {
    return unit.lessons.reduce((prev: Lesson, current: Lesson) => {
      if (current.number === lessonNumber) {
        return current;
      }
      if (prev != null) {
        return prev;
      }
      return null;
    }, null);
  }

  /**
   *
   * @param lesson the lesson from which to get the exercise.  You
   *              can retrieve the lesson from the getLessonFromUnit
   *              method available in this provider.
   * @param exerciseNumber the exercise to retrieve.
   */
  getExerciseFromLesson(lesson: Lesson, exerciseNumber: number): Exercise | null {
    lesson.exercises.forEach(exercise => {
      if (exercise.number === exerciseNumber) {
        return exercise;
      }
    });
    return null;
  }

  private getQuizesFromPouchResult(result): Quiz[] {
    let quizes: Quiz[] = [];
    result.rows.forEach(row => {
      quizes.push(row.doc);
    });
    /*
    We are taking advantage of data structure here.  Both question
    number and answer number are sequential so mapping to an Answer
    object array is possible.
    */
    quizes = quizes
      // Only need to set questions for quiz pages that are not
      // Ad only, so filter out the actual quizes for processing
      .filter(
        quiz => quiz && (quiz.ad === undefined || quiz.ad === false)
      );

    quizes.forEach((quiz: Quiz) => {
      const answers: Answer[] = quiz.answers;

      quiz.questions.forEach((question: any) => {
        // Here we map the stored answer number shown to the
        // Answer array created earlier in the method.
        question.answers = question.answers.map(
          (item: number): Answer => answers[item - 1]
        );
        if (Array.isArray(question.answersShown[0])) {
          // Select exercise types can have multiple selection items shown:
          // basically an array representing the select dropd
          question.answersShown = question.answersShown.map(
            (item: number[]): Answer[] => item.map(i => answers[i - 1])
          );
        } else {
          question.answersShown = question.answersShown.map(
            (item: number): Answer => answers[item - 1]
          );
        }
      });
    });
    return quizes;
  }

  /*getUnitForLesson(lessonNumber: number): Promise<Unit> {
    return this.getUnits()
      .then( (units: Unit[]) => {
        units.forEach( (unit: Unit) => {
          // Let's filter out the unit that has the lesson
          unit.lessons
            .forEach( (lesson: Lesson) => {
              if ( lesson.number === lessonNumber ) {
                return unit;
              }
            });
        });
        return null;
      });
  }

  getLesson(lessonNumber: number): Promise<Lesson> {
    return this.getUnitForLesson(lessonNumber)
      .then( (unit) => {
        return this.getLessonFromUnit(unit, lessonNumber);
      });
  }*/

}
