import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ModalController, NavParams, Platform, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-help-info',
  templateUrl: './help-info.component.html',
  styleUrls: ['./help-info.component.scss'],
})
export class HelpInfoComponent {

  popoverInfo: SafeHtml;
  color: string;

  constructor(
    public viewCtrl: PopoverController,
    navParams: NavParams,
    //public imageViewerCtrl: ImageViewerController,
    public platform: Platform
  ) {
    this.popoverInfo = navParams.get('popoverInfo');
    this.color = navParams.get('color');
  }

  close() {
    this.viewCtrl.dismiss();
  }

  presentImage(myImage) {
    if (this.platform.is('cordova')) {
      const image = document.getElementsByTagName('img').item(2);
      //const imageViewer = this.imageViewerCtrl.create(image);
      //imageViewer.present();
    }
  }

}
