/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { Platform, AlertController, PopoverController, NavController, MenuController, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/models/profile';
import { HomePage } from './home/home.page';
import { CodeRegistrationPage } from './pages/code-registration/code-registration.page';
import { InfoPage } from './pages/info/info.page';
import { PremiumVersionPage } from './pages/premium-version/premium-version.page';
import { TableContentsPage } from './pages/table-contents/table-contents.page';
import { DbService } from './services/db.service';
import { ProfileService } from './services/profile.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';

declare let ga;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  logout: string;
  Stripeurl = 'https://js.stripe.com/v3/';
  AdSenseURl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

  profile: Profile;
  name: string;
  lastName: string;
  imagePath = 'assets/img/user-circle-solid.png';

  user: any = {
    firstName: '',
    lastName: '',
    email: '',
    cemail: '',
    password: '',
    cpassword: '',
    nativeLanguage: '',
    ageRange: ''
  };

  rootPage: any = HomePage;

  pages: Array<{ title: string; component: any; icon: string }>;
  public mainTitle: string = environment.APP_NAME;

  constructor(
    public platform: Platform,
    public nav: NavController,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    public dbProvider: DbService,
    public alertController: AlertController,
    private modalCtrl: ModalController,
    //private adMob: AdMobPro,
    private gaNative: GoogleAnalytics,
    //private events: Events,
    private profileProvider: ProfileService,
    private authService: AuthenticationService,
    private menuController: MenuController
  ) {
    console.log('Entering to the App Component constructor');
    this.logout = localStorage.getItem('userInfo');

    authService.getUserInfo().subscribe((userData) => {

      if (userData) {
        this.name = userData.firstName;
        this.lastName = userData.lastName;
      }

      this.logout = userData;
    });

    // events.subscribe('user:created', (user, time) => {

    //   if (user) {
    //     this.name = user.firstName;
    //     this.lastName = user.lastName;
    //   }

    //   this.logout = user;
    // });

    // used for an example of ngFor and navigation
    this.pages = [
      // {title: 'HOME', component: HomePage, icon: 'home'},
      { title: 'TABLE OF CONTENTS', component: TableContentsPage, icon: 'menu' },
      { title: 'INFO', component: InfoPage, icon: 'information-circle' },
      /*{title: 'GLOSSARY', component: GlossaryPage, icon: 'book'},
      {title: 'CLASS REGISTRATION', component: ClassRegistrationPage, icon: 'create'},*/
      { title: 'PREMIUM VERSION', component: PremiumVersionPage, icon: 'star' },
      { title: 'CLASS REGISTRATION', component: CodeRegistrationPage, icon: 'key' },
    ];
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      this.initializeApp();

      if (this.platform.is('cordova') /* && this.adMob*/) {
        // this.adMob.setOptions({
        //   // adSize: 'SMART_BANNER',
        //   license: ENV['ADMOB_LICENSE'],
        //   isTesting: ENV['ADMOB_TESTING'] // set to true, to receiving test ad for testing purpose
        //   //bgColor: 'black', // color name, or '#RRGGBB'
        //   // autoShow: true // auto show interstitial ad when loaded, set to false if prepare/show
        //   //offsetTopBar: false, // avoid overlapped by status bar, for iOS7+
        // });
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit(): void {
    console.log('Entering to the App Component OnInit');

    this.authService.isAuthenticated.subscribe((isAuthenticated) => {
      this.logout = localStorage.getItem('userInfo');
      this.name = localStorage.getItem('profileFirstName');
      this.lastName = localStorage.getItem('profileLastName');
    });


  }

  initializeApp() {

    this.name = localStorage.getItem('profileFirstName');
    this.lastName = localStorage.getItem('profileLastName');

    if (this.platform.is('cordova')) {
      let gaID = environment.GOOGLE_ANALYTICS_BROWSER; // Won't be the case since we don't use Cordova for browser
      if (this.platform.is('ios')) {
        gaID = environment.GOOGLE_ANALYTICS_IOS;
      } else if (this.platform.is('android')) {
        gaID = environment.GOOGLE_ANALYTICS_ANDROID;
      }

      this.gaNative
        .startTrackerWithId(gaID)
        .then(() => {
          console.log('Google analytics is ready now');
        })
        .catch(e => console.log('Error starting GoogleAnalytics', e));

      // load stripe js

      const loadStripe = document.createElement('script');
      loadStripe.src = this.Stripeurl;
      loadStripe.type = 'text/javascript';
      loadStripe.async = true;
      loadStripe.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(loadStripe);
    } else {
      /*
         Let's load the Google analytics here to guarantee that this is
         happening outside of the cordova platform.  Currently, only the browser is outside
         Cordova so this will only run on the Browser.
        */
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        (i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (i[r].l = 1 * <any>new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        window,
        document,
        'script',
        'https://www.google-analytics.com/analytics.js',
        'ga'
      );
      ga('create', environment.GOOGLE_ANALYTICS_BROWSER, 'auto');

      // load stripe js

      const loadStripe = document.createElement('script');
      loadStripe.src = this.Stripeurl;
      loadStripe.type = 'text/javascript';
      loadStripe.async = true;
      loadStripe.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(loadStripe);

      // load google AdSense

      const loadAdsense = document.createElement('script');
      loadAdsense.src = this.AdSenseURl;
      loadAdsense.async = true;
      loadAdsense.setAttribute('data-ad-client', 'ca-pub-3628817704634856');
      document.getElementsByTagName('head')[0].appendChild(loadAdsense);
    }
  }

  async openPage(page) {
    // Info and TOC can be popovers:
    if (page.title === 'INFO' || page.title === 'TABLE OF CONTENTS') {
      this.menuController.close();
      const popover = await this.modalCtrl.create({
        component: page.component,
        componentProps: {},
        cssClass: 'menu-page-popover'
      });
      popover.present();
    } else if (page.title === 'PREMIUM VERSION') {
      this.menuController.close();
      this.nav.navigateForward(['premium-version']);
    } else if (page.title === 'CLASS REGISTRATION') {
      this.menuController.close();
      this.nav.navigateForward(['code-registration']);
    } else {
      // Reset the content nav to have just this page
      // we wouldn't want the back button to show in this scenario
      //this.nav.setRoot(page.component);
      this.nav.navigateForward(['login']).then(() => {
        this.menuController.close();
      });
    }
  }

  checkLoginStatus() {
    if (localStorage.getItem('login')) {
      this.rootPage = 'LoginPage';
    } else {
      this.rootPage = 'SignupPage';
    }
  }

  async logoutClick() {
    this.logout = undefined;
    this.name = undefined;
    this.lastName = undefined;
    await this.authService.logout();
    //this.events.publish('user:created');
    this.nav.navigateRoot(['home']);
  }
}
