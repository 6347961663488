/* eslint-disable no-underscore-dangle */
import { Component, DoCheck, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent implements OnInit, DoCheck {

  @Input() progress: number;

  text: string;
  _color: string;
  _background: string;
  originalColor: string;

  rotate90: string;
  rotate180: string;
  rotate270: string;
  rotate360: string;

  constructor() {
  }

  @Input()
  set color(color: string) {
    this.originalColor = color;
    this.setColor(color);
  }

  ngOnInit() {
    this.renderProgress();
  }

  ngDoCheck(): void {
    this.renderProgress();
  }

  /**
   * Slight modification of:
   * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
   *
   * @param hex The color hex value that needs to be converted to rgba
   * @param transparency a number between 0 and 1 for amount of transparency
   */
  hexToRgb(hex, transparency) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: transparency
    } : null;
  }

  /*
  Implement: https://web-beta.archive.org/web/20160921141817/http://blog.invatechs.com:80/round_progress_bar_with_html5_css3_and_javascript
  */
  renderProgress() {
    this.setColor(this.originalColor);
    const progress = Math.floor(this.progress);
    let angle;
    if (progress < 25) {
      angle = -90 + (progress / 100) * 360;
      this.rotate90 = 'rotate(' + angle + 'deg)';
      //$(".animate-0-25-b").css("transform","rotate("+angle+"deg)");
    }
    else if (progress >= 25 && progress < 50) {
      angle = -90 + ((progress - 25) / 100) * 360;
      this.rotate90 = 'rotate(0deg)';
      this.rotate180 = 'rotate(' + angle + 'deg)';
      //$(".animate-0-25-b").css("transform","rotate(0deg)");
      //$(".animate-25-50-b").css("transform","rotate("+angle+"deg)");
    }
    else if (progress >= 50 && progress < 75) {
      angle = -90 + ((progress - 50) / 100) * 360;
      this.rotate90 = 'rotate(0deg)';
      this.rotate180 = 'rotate(0deg)';
      this.rotate270 = 'rotate(' + angle + 'deg)';
      //$(".animate-25-50-b, .animate-0-25-b").css("transform","rotate(0deg)");
      //$(".animate-50-75-b").css("transform","rotate("+angle+"deg)");
    }
    else if (progress >= 75 && progress <= 100) {
      angle = -90 + ((progress - 75) / 100) * 360;
      this.rotate90 = 'rotate(0deg)';
      this.rotate180 = 'rotate(0deg)';
      this.rotate270 = 'rotate(0deg)';
      this.rotate360 = 'rotate(' + angle + 'deg)';
      //$(".animate-50-75-b, .animate-25-50-b, .animate-0-25-b")
      //                                      .css("transform","rotate(0deg)");
      //$(".animate-75-100-b").css("transform","rotate("+angle+"deg)");
    }
    //$(".text").html(progress+"%");
  }

  private setColor(color: string) {
    let transparency = 0.5;
    if (this.progress !== undefined && this.progress === 0) {
      transparency = 0.2;
    }
    const rgba = this.hexToRgb(color, transparency);
    this._background = 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + rgba.a + ')';
    this._color = 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + 1 + ')';
  }

}
